import React from "react";
import "./style.css";
import img6 from "../Component/Group 366.png";
const Fifteen_section = () => {
  return (
    <>
      <div className="fifth_main1">
        <div className="fifth_11">
          <h1>Capital Guarantee</h1>

          <ul>
            <li>
              <span className="span_11">01</span>
              <div className="span_22">
                For each asset we set a max-loss value of 1% of capital
              </div>
            </li>
            <li>
              <span className="span_11">02</span>
              <div className="span_22">
                In the eventuality that an asset loses the above 1%,it's always
                covered up by other assets Average loss =ZERO
              </div>
            </li>
            <li>
              <span className="span_11">03</span>
              <div className="span_22">
                When we say ZERO risk,we really mean it.Our clients have never
                lost a rupee
              </div>
            </li>
          </ul>
        </div>
        <div className="fifth_2">
          <img src={img6} />
        </div>
      </div>
    </>
  );
};

export default Fifteen_section;
