import React from "react";
import "./style.css";
import img7 from "../Component/Group 359.png";
const Nine_section = () => {
  return (
    <>
      <div className="nine_main">
        <img src={img7} />
      </div>
    </>
  );
};

export default Nine_section;
