import React from "react";
import "./style.css";
import img6 from "../Component/Artboard -2.png";
const Eight_section = () => {
  return (
    <>
      <div className="H_2">
        <h2>Core Team</h2>
      </div>
      <div className="eight_main">
        <div className="eight_1">
          <img src={img6} />
          <h3>Madhankumar Reddy</h3>
          <p>INVESTOR IN CHIEF</p>
        </div>
        <div className="eight_1">
          <img src={img6} />
          <h3>Madhankumar Reddy</h3>
          <p>INVESTOR IN CHIEF</p>
        </div>
        <div className="eight_1">
          <img src={img6} />
          <h3>Madhankumar Reddy</h3>
          <p>INVESTOR IN CHIEF</p>
        </div>
        <div className="eight_1">
          <img src={img6} />
          <h3>Madhankumar Reddy</h3>
          <p>INVESTOR IN CHIEF</p>
        </div>
      </div>
    </>
  );
};

export default Eight_section;
