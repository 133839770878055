import React from "react";
import Header from "./Header";
import Hero from "./Hero";
import First_section from "./First_section";
import Second_section from "./Second_section";
import Thired_section from "./Thired_section";
import Forth_section from "./Forth_section";
import Fifth_section from "./Fifth_section";
import Six_section from "./Six_section";
import Seven_section from "./Seven_section";
import Eight_section from "./Eight_section";
import Nine_section from "./Nine_section";
import Price_section from "./Price_section";

const First_page = () => {
  return (
    <>
      <Header />
      <Hero />
      <First_section />
      <Second_section />
      <Thired_section />
      <Forth_section />
      <Fifth_section />
      <Six_section />

      <Seven_section />
      <Price_section />
      <Eight_section />
      <Nine_section />
    </>
  );
};

export default First_page;
