import React from "react";
import "./style.css";
import img6 from "../Component/Group 366.png";
const Fifth_section = () => {
  return (
    <>
      <div className="fifth_main">
        <div className="fifth_1">
          <h1>Risk Managmnent</h1>
          <hr></hr>
          <p>
            Vealth 1st Algo Management prioritizes the safety and security of
            your capital through advanced algorithmic trading solutions.
          </p>
          <ul>
            <li>
              <span className="span_1">01</span>
              <div className="span_2">
                The algorithmic trading system has a strict risk management
                framwork with a maximum drawdown limit of 10%
              </div>
            </li>
            <li>
              <span className="span_1">02</span>
              <div className="span_2">
                This predefined risk limit safeguards investments and offers
                stability in volatile markets
              </div>
            </li>
            <li>
              <span className="span_1">03</span>
              <div className="span_2">
                Vealth 1st Algo optimizes position sizing based on tatl capital
                and risk measures
              </div>
            </li>
            <li>
              <span className="span_1">04</span>
              <div className="span_2">
                This Approach ensures balanced growth by preventing any single
                trade from significantly impacting the portfolio.
              </div>
            </li>
          </ul>
        </div>
        <div className="fifth_2">
          <img src={img6} />
        </div>
      </div>
    </>
  );
};

export default Fifth_section;
