import React from "react";
import "./style.css";
const Price_section = () => {
  return (
    <>
      <div className="Seventee">
        <div className="price_h1">
          <h1>Our Plans</h1>
        </div>
        <div className="price_main">
          <div className="price_main_1">
            <h2 style={{ color: "red" }}>1 Month</h2>
            <h2>3,00,000</h2>
            <p>
              Max 10% <br></br> of capital
            </p>
            <h2 style={{ color: "red" }}>2,999*</h2>
            <p>
              +18% GST <br></br>15 Days free trial
            </p>
            <button className="price_btn">Subscribe Now</button>
          </div>
          <div className="price_main_1">
            <h2 style={{ color: "red" }}>3 Month</h2>
            <h2>3,00,000</h2>
            <p>
              Max 10% <br></br> of capital
            </p>
            <h2 style={{ color: "red" }}>8,999*</h2>
            <p>
              +18% GST <br></br>15 Days free trial
            </p>
            <button className="price_btn">Subscribe Now</button>
          </div>
          <div className="price_main_1">
            <h2 style={{ color: "red" }}>6 Month</h2>
            <h2>3,00,000</h2>
            <p>
              Max 10% <br></br> of capital
            </p>
            <h2 style={{ color: "red" }}>17,999*</h2>
            <p>
              +18% GST <br></br>15 Days free trial
            </p>
            <button className="price_btn">Subscribe Now</button>
          </div>
          <div className="price_main_1">
            <h2 style={{ color: "red" }}>12 Month</h2>
            <h2>3,00,000</h2>
            <p>
              Max 10% <br></br> of capital
            </p>
            <h2 style={{ color: "red" }}>35,999*</h2>
            <p>
              +18% GST <br></br>15 Days free trial
            </p>
            <button className="price_btn">Subscribe Now</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Price_section;
