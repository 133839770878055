import React from "react";
import "./style.css";
import img2 from "../Component/Group 357.png";
const Fourteen_section = () => {
  return (
    <>
      <div className="main_third1">
        <div className="third1">
          <div className="left_div">
            <img src={img2} />
          </div>
          <div className="right_div">
            <h1 style={{ fontSize: "50px", fontWeight: "700" }}>
              How we do it{" "}
            </h1>
            <hr className="hr_class"></hr>
            <p className="p_1">
              At any point of time there are assets on the bull run. That is
              just how markets work, when something is going down,there is
              always something that is going up.
            </p>

            <p className="p_2">
              identify these assets on the bull run and we allocate your
              investments to ride upward curve . we do this on a real-time basis
              day in and day out.
            </p>

            <p className="p_3">
              it sounds simple,but we've reached this stage with over a decade
              of dedication and passion to perform at the markets{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Fourteen_section;
