import React from "react";
import "./style.css";
const First_section = () => {
  return (
    <>
      <div className="section1">
        <p className="p_section">
          Start Your Journey with Vealth1st: subscribe Today
        </p>
        <div className="input_type">
          <input type="text" placeholder="Name" className="search-input" />
          <input type="number" placeholder="Mobile" className="search-input" />
          <input type="email" placeholder="email" className="search-input" />
        </div>
        <p className="p_section2">
          Do you have the potential to Invest minimum 3lakh and above ?
        </p>
        <div className="but_text">
          <button className="button_section">Yes</button>
          <button className="button_section">No</button>
        </div>
        <p className="p_section3">
          By completing this form,you consent to Vealth's Privacy Policy
          <button className="button_now">Subscribe Now</button>
        </p>
      </div>
    </>
  );
};

export default First_section;
