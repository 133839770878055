import React from "react";
import "./style.css";
import fig1 from "../Component/Watch.png";
import fig2 from "../Component/Group 498.png";
const Sixteen_section = () => {
  return (
    <>
      <div className="section2">
        <div className="section21">
          <div className="section211">
            <div className="fig_left">
              <img src={fig1} />
            </div>
            <div className="fig_right">
              <h1 className="h_1">SAVING IS NOT INVESTING</h1>
              <h1 style={{ color: "red" }} className="h_2">
                INVESTING IS A FULL TIME OCCUPATION
              </h1>
              <p className="p_tag">
                It involves deep research,meticulous analysis, and dedicated
                execution.
              </p>
            </div>
          </div>
          <div className="img_tag1">
            <h2 className="h_3">
              HOW DO YOU FIND TIME TO INVEST <br></br> IN YOUR BUSY SCHEDULE?
            </h2>
            <p className="p">
              Stocks India is your ultimate Solution for building wealth
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sixteen_section;
