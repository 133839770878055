import React from "react";

import Nine_section from "./Nine_section";
import Tenth_section from "./Tenth_section";
import Eleven_section from "./Eleven_section";
import First_section from "./First_section";
import Fourteen_section from "./Fourteen_section";
import Fifteen_section from "./Fifteen_section";
import Sixteen_section from "./Sixteen_section";
import Seventeen_section from "./Seventeen_section";
import Nineteen_section from "./Nineteen_section";
import Price_section from "./Price_section";

const Second_page = () => {
  return (
    <>
      <Tenth_section />
      <Eleven_section />
      <First_section />
      <Sixteen_section />
      <Fourteen_section />
      <Fifteen_section />

      <Seventeen_section />
      <Price_section />
      <Nineteen_section />
      <Nine_section />
    </>
  );
};

export default Second_page;
