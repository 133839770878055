import React from "react";
import "./style.css";
import robot1 from "../Component/robot.png";
import your_video from "../Component/video-2.mp4";
const Hero = () => {
  return (
    <>
      <div class="video-background">
        <video autoPlay muted loop id="bg-video">
          <source src={your_video} type="video/mp4" />
        </video>

        <div className="header">
          <div className="leftdiv">
            <h1>
              Unlock Your <span style={{ color: "red" }}>Financial</span>
              <br></br>
              Potential with <span style={{ color: "red" }}>Vealth1st</span>
              <br></br>
              <span style={{ color: "red" }}>Algorithm</span>
            </h1>
            <p>The future of intelligent wealth building </p>
            <br></br>
            <button className="subbtn">Subscribe</button>
          </div>
          <div className="robot">
            <img src={robot1}></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
