import React from "react";
import "./style.css";
import logo1 from "../Component/logo1.png";
const Tenth_section = () => {
  return (
    <>
      <div className="header">
        <div className="main1">
          <img src={logo1}></img>
        </div>
        <div className="navemenu">
          <ul>
            <li>About Us </li>
            <li>Our Advantage</li>
            <li>Subscription Plan</li>
            <li>Success Story</li>
            <li>Blogs</li>
            <li>Contect Us</li>
            <li>
              <i class="fa-solid fa-bars"></i>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Tenth_section;
