import React, { Component } from "react";
import "./style.css";
import fig1 from "../Component/Group 497.png";
import fig2 from "../Component/Group 498.png";
const Second_section = () => {
  return (
    <>
      <div className="section2">
        <img src={fig1} />
        <p className="p_tag">
          To Build wealth we need discipline,consistency,<br></br>
          and control over our emotions
        </p>
        <div className="img_tag">
          <img src={fig2} />
        </div>
      </div>
    </>
  );
};

export default Second_section;
