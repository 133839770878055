import React from "react";
import "./style.css";
const Forth_section = () => {
  return (
    <>
      <div className="four">
        <div className="four_main">
          <div className="div_1">
            <h3>Comparing Alogorithms with Humans</h3>
            <p>
              Algorithms excel in speed ,accuracy, and consistency compared to
              humans
            </p>
            <br></br>
            <p>
              Humans manage emotions better but algorithms outperform in trading
              performance
            </p>
          </div>
          <div className="div_2">
            <h3>Maximize Return </h3>
            <p>
              Algorithms optimize investment strategies and enhance portfolio
              performance.
            </p>
            <br></br>
            <p>
              They analyze market data identify profitable opportunities and
              adapt through back testing
            </p>
          </div>

          <div className="div_3">
            <h3>Minimize Losses </h3>
            <p>
              Risk management is integral to algorithmic trading involving stop
              loss order,hedging and diversification.
            </p>
            <br></br>
            <p>
              Automated strategies help control and mitigate risk effectively
            </p>
          </div>
        </div>
        <p className="four_p">
          Algorithmic trading automates the entire process from analysis to
          order execution .<br></br>
          It remove human error and emotional influence ensuring consistent
          trading strategles
        </p>
      </div>
    </>
  );
};

export default Forth_section;
