import React from "react";
import "./style.css";
import img_11 from "../Component/Super.png";
import img_12 from "../Component/Super_1.png";
import img_13 from "../Component/Super_3.png";
const Seventeen_section = () => {
  return (
    <>
      <div className="Seventeen">
        <div>
          <h1 className="heading_tag">The Stocks-India Advantage</h1>
        </div>
        <div className="main_div7">
          <div className="img_div1">
            <img src={img_11} />
            <p>
              Super-charged <br></br>
              propreitary algorithms
            </p>
          </div>
          <div className="img_div1">
            <img src={img_12} />
            <p>
              Disciplined meticulous <br></br>
              risk mitigating processes{" "}
            </p>{" "}
          </div>
          <div className="img_div1">
            <img src={img_13} />
            <p>
              processes designed from a,<br></br> decase of expert insights
            </p>
          </div>
        </div>
        <div>
          <div className="footer">
            <div className="footer_4">
              <p className="footer_1">
                "We believe that key factor that will determine your financial
                future is not the economy, its the strategy of your
                investments."
              </p>
              <p className="footer_2">"Madhan Reddy. Founder "</p>
            </div>
            <div className="footer_3">
              <p className="footer_5">
                <span className="footer_6">10</span>
                Years investing
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Seventeen_section;
