import { useState } from "react";
import "./App.css";

import "./Component/style.css";
import First_page from "./Component/First_page";
import Second_page from "./Component/Second_page";

const App = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <>
      <div>
        {currentPage === 1 ? <First_page /> : <Second_page />}
        <div className="fixed">
          <button
            className={currentPage === 1 ? "active" : ""}
            onClick={() => handlePageChange(1)}
          ></button>
          <button
            className={currentPage === 2 ? "active" : ""}
            onClick={() => handlePageChange(2)}
          ></button>
        </div>
      </div>
    </>
  );
};

export default App;
