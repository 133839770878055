import React from "react";
import "./style.css";
const Seven_section = () => {
  return (
    <>
      <div className="seven_main">
        <div className="seven_1">
          <h3>Risk-Adjusted Returns</h3>
          <p>
            Even with the compounding effect our strict risk management
            protocols remain in place ensuring that your investments grow within
            predefined risk limits
          </p>
        </div>
        <div className="seven_2">
          <h3>Efficiency</h3>
          <p>
            Automated reinvestment ensures that your capital is always working
            for you without the need for manual intervention
          </p>
        </div>
        <div className="seven_3">
          <h3>Exponential Growth </h3>
          <p>
            Compounding allows your portfolio to grow at an accelerated rate
            maximizing long-term returns
          </p>
        </div>
      </div>
    </>
  );
};

export default Seven_section;
