import React from "react";
import "./style.css";
import robot1 from "../Component/robot.png";
import your_video from "../Component/video-2.mp4";
const Eleven_section = () => {
  return (
    <>
      <div class="video-background">
        <video autoPlay muted loop id="bg-video">
          <source src={your_video} type="video/mp4" />
        </video>
        <div className="header">
          <div className="leftdiv1">
            <h1>
              Your one step <span style={{ color: "red" }}>Solutions</span>
              <br></br>
              for <span style={{ color: "red" }}>Wealth</span> building
            </h1>
            <p>
              Maximize returns with our expert guidance,transparent
              <br></br>
              approach,and advanced algo-trading technology
            </p>
            <br></br>
            <button className="subbtn1">try Demo</button>
            <button className="subbtn2">Lear More</button>
          </div>
          <div className="robot">
            <img src={robot1}></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default Eleven_section;
