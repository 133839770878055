import React from "react";
import "./style.css";
import img2 from "../Component/Group 357.png";

const Thired_section = () => {
  return (
    <>
      <div className="main_third">
        <h1> Fully Automated Algorithms</h1>
        <div className="third">
          <div className="left_div">
            <img src={img2} />
          </div>
          <div className="right_div">
            <p>
              Algorithms excel in executing trades with precision and speed
              unlike humans, algorithms can process vast amounts of data and
              execute complex strategies without error or hesitation. This level
              of perfection minimizes the risk of human error, ensuring
              consistent and reliable performance.
            </p>
            <ul className="ul_third">
              <li>
                Algorithms can execute trades in milisecond capturing
                opportunities that humans con't do
              </li>
              <li>
                By following pre-set rules algorithms elements emotional
                decision making leading to more consistent results
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Thired_section;
