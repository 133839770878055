import React from "react";
import "./style.css";
import img8 from "../Component/Group 511.png";
const Six_section = () => {
  return (
    <>
      <div className="six_section">
        <h1>Compounding</h1>
        <p>
          Vealth 1st utilizes innovative compounding strategies to accelerate
          investing growth by reinvesting earnings. This approach aims to
          deliver exponential growth and secure financial futures
        </p>
        <div>
          <img src={img8} />
        </div>
      </div>
    </>
  );
};

export default Six_section;
