import React from "react";
import "./style.css";
import img1 from "../Component/CHIEF.png";
import img2 from "../Component/CMO.png";
import img3 from "../Component/COO.png";
import img4 from "../Component/CFO.png";
const Nineteen_section = () => {
  return (
    <>
      <div className="main_class_9">
        <div className="main_class_91">
          <h1>Core Team</h1>
        </div>
        <div className="main_class_92">
          <p>
            Hear's a brief look at our core team that thrives on delighting
            clientele with ROI and monetary miracles
          </p>
        </div>
      </div>
      <div className="img_class">
        <div className="img_class1">
          <img src={img1} />
          <h3>INVESTOR IN CHIEF </h3>
          <hr></hr>
          <p>Madhankumar Reddy </p>
        </div>
        <div className="img_class1">
          <img src={img2} />
          <h3>CMO </h3>
          <hr></hr>
          <p>Bhavya </p>
        </div>
        <div className="img_class1">
          <img src={img3} />
          <h3>COO</h3>
          <hr></hr>
          <p>Mansoor Mohamed </p>
        </div>
        <div className="img_class1">
          <img src={img4} />
          <h3>CFO </h3>
          <hr></hr>
          <p>Balu </p>
        </div>
      </div>
    </>
  );
};

export default Nineteen_section;
